import { makeAutoObservable, runInAction } from "mobx";
import {
  fetchProducts,
  updateProductById,
  deleteProduct as deleteProductAPI,
} from "../utilities/productAPI";
import { getCategory } from "../utilities/categoryUtils";
import { ALL_PRODUCTS_CATEGORY } from "../utilities/constants";

class ProductsStore {
  products = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async loadProducts(refresh = false) {
    if (this.products.length && !refresh) return;

    this.loading = true;
    try {
      const data = await fetchProducts();
      runInAction(() => {
        this.products = data;
        this.loading = false;
      });
    } catch (err) {
      console.error("Failed to load products:", err);
      this.loading = false;
    }
  }
  async updateProduct(originalProductId, productData) {
    try {
      const updated = await updateProductById(originalProductId, productData);
      runInAction(() => {
        const index = this.products.findIndex((p) => p.id === updated.id);
        if (index !== -1) {
          this.products[index] = updated;
        } else {
          this.products.push(updated);
        }
      });
      return updated;
    } catch (err) {
      console.error("Failed to update product:", err);
      throw err;
    }
  }

  async deleteProduct(productId) {
    try {
      await deleteProductAPI(productId);
    } catch (err) {
      console.error("Failed to delete product", err);
    }
  }

  getProductsByCategory(categoryInput) {
    if (!this.products.length) return [];

    const category = getCategory(categoryInput);

    // Fallback: If it's explicitly 'all-products' or matches that alias
    const normalizedInput = categoryInput?.toLowerCase().replace(/\s+/g, "-");
    if (
      normalizedInput === "all-products" ||
      category.name?.toLowerCase() === "all products"
    ) {
      return this.products
        .slice()
        .sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
    }

    if (!category?.name) return [];

    const normalize = (str) => str.toLowerCase().replace(/\s+/g, "-");
    const mainCategory = normalize(category.name);
    const altCategories = (category.alt_names || []).map(normalize);

    return this.products
      .filter((product) => {
        const productCategories = product.category.map(normalize);
        return (
          productCategories.includes(mainCategory) ||
          productCategories.some((cat) => altCategories.includes(cat))
        );
      })
      .sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
  }

  get allProducts() {
    return this.products
      .slice()
      .sort((a, b) => new Date(b.release_date) - new Date(a.release_date));
  }
}

const productsStore = new ProductsStore();
export default productsStore;
