import React, { useEffect, useState } from "react";
import Lightbox from "../Lightbox/Lightbox";
import S3ImageManagerHeader from "./S3ImageManagerHeader";
import S3ImageManagerGrid from "./S3ImageManagerGrid";
import S3ImageManagerList from "./S3ImageManagerList";
import ManagerLinkModal from "../modals/ManagerLinkModal";
import ManagerUploadModal from "../modals/ManagerUploadModal";
import s3ImagesStore from "../../stores/S3ImagesStore"; // ✅ import MobX store
import { observer } from "mobx-react-lite";
import productsStore from "../../stores/ProductsStore";

const S3ImageManager = () => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [sortBy, setSortBy] = useState("name");
  const [viewMode, setViewMode] = useState("grid");
  const [searchQuery, setSearchQuery] = useState("");
  const [showLinkModal, setShowLinkModal] = useState(false);
  const [linkingImage, setLinkingImage] = useState(null);
  const [showUploadModal, setShowUploadModal] = useState(false);

  const handleUploadSuccess = (newImage) => {
    s3ImagesStore.addImage(newImage); // ✅ store-aware
    setLinkingImage(newImage);
    setShowLinkModal(true);
  };
  

  useEffect(() => {
    s3ImagesStore.fetchImages();
  }, []);
  
  const handleLink = (imageObj) => {
    setLinkingImage(imageObj);
    setShowLinkModal(true);
  };

  const handleLinkSave = async ({
    filename,
    product_id,
    sku_ids,
    previous_product_id,
  }) => {
    try {
      // ✅ Call backend to update image metadata
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/images/metadata/${filename}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
          },
          body: JSON.stringify({
            product_id,
            sku_ids,
            previous_product_id,
          }),
        }
      );
  
      if (!res.ok) {
        const errText = await res.text();
        throw new Error(`Failed to link image: ${errText}`);
      }
  
      const updatedData = await res.json();
  
      // ✅ Update MobX store with backend-confirmed data
      s3ImagesStore.updateImageMetadata(
        filename,
        updatedData.product_id,
        updatedData.sku_ids
      );
  
      setShowLinkModal(false);
      setLinkingImage(null);
      await productsStore.loadProducts(true); // 🔄 Refresh product data
  
    } catch (err) {
      console.error("Link error", err);
      alert("Failed to update image association.");
    }
  };
  

  const handleDelete = async (imageObj) => {
    s3ImagesStore.deleteImage(imageObj.filename);
setSelectedImage(null);

  };
  const filteredImages = s3ImagesStore.images.filter(
    (img) =>
      img.filename.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (img.associatedIds || []).some((id) =>
        id.toLowerCase().includes(searchQuery.toLowerCase())
      )
  );

  const getSortedImages = () => {
    const sorted = [...filteredImages];
    if (sortBy === "size") {
      sorted.sort((a, b) => (b.size || 0) - (a.size || 0));
    } else if (sortBy === "usage") {
      sorted.sort((a, b) => Number(a.used) - Number(b.used));
    } else {
      sorted.sort((a, b) => a.filename.localeCompare(b.filename));
    }
    return sorted;
  };

  return (
    <div style={{ padding: "2rem", width: "80%" }}>
      <S3ImageManagerHeader
        images={s3ImagesStore.images}
        sortBy={sortBy}
        setSortBy={setSortBy}
        viewMode={viewMode}
        setViewMode={setViewMode}
        searchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        onUploadClick={() => setShowUploadModal(true)}
      />
      {s3ImagesStore.loading ? (
        <div className="spinner" />
      ) : viewMode === "grid" ? (
        <S3ImageManagerGrid
          images={getSortedImages()}
          onSelect={setSelectedImage}
          onDelete={handleDelete}
          onLink={handleLink}
        />
      ) : (
        <S3ImageManagerList
          images={getSortedImages()}
          onSelect={setSelectedImage}
          onDelete={handleDelete}
          onLink={handleLink}
        />
      )}
      {showLinkModal && linkingImage && (
        <ManagerLinkModal
          image={linkingImage}
          onClose={() => {
            setShowLinkModal(false);
            setLinkingImage(null);
          }}
          onSave={handleLinkSave}
        />
      )}
      {showUploadModal && (
        <ManagerUploadModal
          onClose={() => setShowUploadModal(false)}
          onUploadSuccess={handleUploadSuccess}
        />
      )}
      {selectedImage && (
        <Lightbox
          photos={[selectedImage.url]}
          onClose={() => setSelectedImage(null)}
          showThumbnails={false}
        ></Lightbox>
      )}
    </div>
  );
};

export default observer(S3ImageManager);
