import React, { useState, useRef, useEffect } from "react";

const Lightbox = ({
  photos,
  selectedPhoto,
  onClose,
  showThumbnails = true,
}) => {
  const [selectedImage, setSelectedImage] = useState(
    selectedPhoto || photos[0]
  );
  const thumbnailRefs = useRef([]);

  useEffect(() => {
    // Disable scrolling when lightbox is open
    document.body.style.overflow = "hidden";

    return () => {
      // Re-enable scrolling when lightbox is closed
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleThumbnailClick = (photo, index) => {
    setSelectedImage(photo);
    // Scroll the selected thumbnail into view
    thumbnailRefs.current[index]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  };

  return (
    <>
      {/* Transparent Overlay - Clicking it closes the lightbox */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          zIndex: 999,
        }}
        onClick={onClose} // Clicking overlay closes modal
      />

      {/* Lightbox Content */}
      <div
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100vw",
          height: "100vh",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)", // for Safari
          backgroundColor: "rgba(0, 0, 0, 0.4)", // semi-transparent dark overlay
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 1000,
        }}
        onClick={onClose}
      >
        {/* Close Button - "X" Positioned in Top-Right */}
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "rgba(255, 255, 255, 0.1)",
            border: "none",
            color: "#fff",
            fontSize: "20px",
            cursor: "pointer",
            padding: "5px 10px",
            borderRadius: "5px",
            zIndex: 10,
          }}
        >
          ✕
        </button>

        {/* Full-Size Image */}
        <div
          style={{
            marginBottom: "20px",
            maxWidth: "90%",
            maxHeight: "70vh",
            padding: "8px",
            borderRadius: "10px",
            border: "1px solid rgba(255, 255, 255, 0.2)", // Same border as thumbnails
            background: "rgba(255, 255, 255, 0.05)", // Light background
          }}
          onClick={(e) => e.stopPropagation()} // Prevent closing when clicking the image
        >
          <img
            src={selectedImage}
            alt="Selected"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              borderRadius: "10px",
            }}
          />
        </div>

        {/* Horizontal Thumbnail Scroller */}
        {showThumbnails && (
          <div
            style={{
              display: "flex",
              gap: "10px",
              overflowX: "auto",
              maxWidth: "90%",
              padding: "8px",
              paddingBottom: "5px",
              borderRadius: "10px",
              border: "1px solid rgba(255, 255, 255, 0.2)", // Thin border
              background: "rgba(255, 255, 255, 0.05)", // Light background
              scrollbarWidth: "thin", // Firefox scrollbar
              scrollbarColor: "rgba(255, 255, 255, 0.5) transparent",
            }}
            onClick={(e) => e.stopPropagation()} // Prevent closing when clicking thumbnails
          >
            {photos.map((photo, index) => (
              <img
                key={index}
                src={photo}
                alt={`Thumbnail ${index + 1}`}
                ref={(el) => (thumbnailRefs.current[index] = el)} // Save ref for scrolling
                onClick={() => handleThumbnailClick(photo, index)} // Center thumbnail on click
                style={{
                  width: "50px",
                  height: "50px",
                  objectFit: "cover",
                  borderRadius: "8px",
                  cursor: "pointer",
                  border:
                    selectedImage === photo
                      ? "2px solid rgba(168, 85, 247, 0.8)"
                      : "2px solid rgba(255, 255, 255, 0.2)",
                  flexShrink: 0,
                  transition: "border 0.2s ease-in-out",
                }}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default Lightbox;
