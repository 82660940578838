import React from "react";
import DeleteButton from "../buttons/DeleteButton";
import ManagerLinkButton from "../buttons/ManagerLinkButton";
import productsStore from "../../stores/ProductsStore";
import { isImageLinked } from "../../utilities/imageUtils";

const S3ImageManagerGrid = ({ images, onSelect, onDelete, onLink }) => {
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
        gap: "16px",
      }}
    >
      {images.map((imageObj) => {
        const linked = isImageLinked(imageObj.filename, productsStore.products);

        return (
          <div
            key={imageObj.filename}
            style={{
              background: "rgba(255,255,255,0.05)",
              padding: "10px",
              borderRadius: "10px",
              border: linked ? "2px solid #4ade80" : "2px dashed #f87171",
              textAlign: "center",
              position: "relative",
            }}
          >
            <img
              src={imageObj.url}
              alt={imageObj.filename}
              onClick={() => onSelect(imageObj)}
              style={{
                width: "100%",
                cursor: "zoom-in",
                borderRadius: "6px",
              }}
            />
            <p
              style={{
                fontSize: "12px",
                marginTop: "6px",
                wordBreak: "break-word",
              }}
            >
              {imageObj.filename}
            </p>
            <p style={{ fontSize: "11px", color: "#999" }}>
              {(imageObj.size / 1024).toFixed(1)} KB
            </p>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <ManagerLinkButton
                isLinked={linked}
                onClick={() => onLink(imageObj)}
              />
              <span
  title={linked ? "Image is in use and cannot be deleted." : ""}
  style={{ display: "inline-flex" }} // ensures layout is preserved
>
  <DeleteButton
    onClick={() => onDelete(imageObj)}
    disabled={linked}
  />
</span>

            </div>
          </div>
        );
      })}
    </div>
  );
};

export default S3ImageManagerGrid;
