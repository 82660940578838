import React from "react";

const SkuSelector = ({ skus, selectedSku, onSkuChange }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        gap: "5px",
        marginTop: "10px",
        marginBottom: "10px",
        flexWrap: "wrap",
        height: "50px",
        alignItems: "center",
      }}
    >
      {skus.map((sku) => (
        <button
          key={sku.id}
          onClick={(e) => {
            e.stopPropagation(); // Prevent modal from opening
            onSkuChange(sku.id);
          }}
          style={{
            padding: "5px 10px",
            fontSize: "12px",
            color: selectedSku.id === sku.id ? "#000" : "#eaeaea",
            backgroundColor: selectedSku.id === sku.id ? "#f0f0f0" : "transparent",
            border: selectedSku.id === sku.id
              ? "1px solid #f0f0f0"
              : "1px solid rgba(255, 255, 255, 0.3)",
            borderRadius: "50px",
            cursor: "pointer",
            transition: "background-color 0.3s, color 0.3s",
            whiteSpace: "nowrap",
          }}
        >
          {sku.label}
        </button>
      ))}
    </div>
  );
};

export default SkuSelector;
