import React, { useEffect, useState, useRef } from "react";
import { IoClose } from "react-icons/io5";
import CancelButton from "../buttons/CancelButton";
import SaveButton from "../buttons/SaveButton";
import SkuMaker from "../ProductManager/SkuMaker";
import ProductEditor from "../ProductManager/ProductEditor";
import ProductIdEditor from "../ProductManager/ProductIdEditor";
import S3ImageGridItem from "../Images/S3ImageGridItem";
import ImagePickerModal from "../modals/ImagePickerModal";
import { slugify } from "../../utilities/stringUtils";
import productsStore from "../../stores/ProductsStore";
import s3ImagesStore from "../../stores/S3ImagesStore";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
import { duplicateSkuAtIndex } from "../../utilities/productUtils";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const CDN_BASE_URL = "https://cdn.karsho.com/product_images/";

const ProductEditModal = ({ product, onClose, onSuccess }) => {
  const modalRef = useRef(null);
  const [originalProductId] = useState(product.id);
  const [productId, setProductId] = useState(product.id);
  const [autoGenerateId, setAutoGenerateId] = useState(false);
  const [title, setTitle] = useState(product.title);
  const [description, setDescription] = useState(product.description || "");
  const [defaultPrice, setDefaultPrice] = useState(product.default_price);
  const [defaultShippingInfo, setDefaultShippingInfo] = useState(
    product.default_shipping_info || ""
  );
  const [designedFor, setDesignedFor] = useState(product.designed_for || "");
  const [instructions, setInstructions] = useState(product.instructions || "");
  const [productVideo, setProductVideo] = useState(product.product_video || "");
  const [releaseDate, setReleaseDate] = useState(product.release_date || "");
  const [photos, setPhotos] = useState(product.product_photos || []);
  const [showImagePicker, setShowImagePicker] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [skuErrors, setSkuErrors] = useState({});
  const [category, setCategory] = useState(product?.category || []);

  
  const normalizeSkus = (skus = []) =>
    skus.map((sku) => ({
      id: sku.id || "",
      label: sku.label || "",
      skuDescription: sku.sku_description || "",
      skuDesignedFor: sku.sku_designed_for || "",
      material: sku.material || "",
      hardwareIncluded: sku.hardware_included ? "yes" : "no",
      weight: sku.weight || "",
      price: sku.price || "",
      stock: sku.stock || "",
      madeOnOrder: sku.made_on_order || false,
      shippingInfo: sku.shipping_info || "",
      onSale: sku.on_sale || false,
      salePrice: sku.sale_price || "",
      image: sku.image || null,
    }));

  const [skus, setSkus] = useState(normalizeSkus(product.skus));

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "auto");
  }, []);

  const handleSkuChange = (index, field, value) => {
    const updated = [...skus];
    updated[index][field] = value;
    setSkus(updated);
  };

  const removeSku = (index) => {
    if (skus.length <= 1) return;
    setSkus((prev) => prev.filter((_, i) => i !== index));
  };

  const handleSave = async () => {
    setSubmitting(true);
    setSkuErrors({}); // reset

    const processedSkus = skus.map((sku, index) => ({
      id: sku.id || `${slugify(title)}-sku-${index + 1}`,
      label: sku.label,
      image: sku.image || null,
      sku_description: sku.skuDescription || null,
      sku_designed_for: sku.skuDesignedFor || null,
      material: sku.material || "",
      hardware_included:
        sku.hardwareIncluded?.toLowerCase() === "yes" ||
        sku.hardwareIncluded === true,
      weight: sku.weight || null,
      price: sku.price ? parseFloat(sku.price) : 0,
      stock: parseInt(sku.stock || 0),
      made_on_order: sku.madeOnOrder === true,
      shipping_info: sku.shippingInfo || null,
      on_sale: sku.onSale === true,
      sale_price: sku.salePrice ? parseFloat(sku.salePrice) : 0,
    }));
    const payload = {
      id: productId,
      title,
      default_price: parseFloat(defaultPrice),
      default_shipping_info: defaultShippingInfo || null,
      description: description || null,
      designed_for: designedFor || null,
      instructions: instructions || null,
      product_video: productVideo || null,
      release_date: releaseDate || null,
      category: category,
      product_photos: photos,
      skus: processedSkus,
    };

    try {
      await productsStore.updateProduct(originalProductId, payload);
      toast.success("Product updated successfully");
      onSuccess?.();
      onClose();
    } catch (err) {
      let message = "Failed to update product.";

      // ✅ Ensure we're reading err.detail deeply
      const detail = err?.detail;

      if (detail && typeof detail === "object") {
        if (detail.error_type === "duplicate_sku") {
          const duplicateSkuId = detail.sku_id;
          const index = skus.findIndex((sku) => sku.id === duplicateSkuId);
          if (index !== -1) {
            setSkuErrors({
              [index]: `SKU ID "${duplicateSkuId}" already exists.`,
            });
            toast.error(`Duplicate SKU ID "${duplicateSkuId}"`);
            return;
          }
        }

        if (detail.message) {
          message = detail.message;
        }
      }

      toast.error(`❌ ${message}`);
    } finally {
      setSubmitting(false);
    }
  };

  const handleImageSelect = (image) => {
    if (!photos.includes(image.filename)) {
      setPhotos((prev) => [...prev, image.filename]);
    }
    setShowImagePicker(false);
  };
  const duplicateSku = (index) => {
    setSkus((prev) => duplicateSkuAtIndex(prev, index));
  };

  return (
    <div
      onClick={onClose}
      style={{
        position: "fixed",
        inset: 0,
        background: "rgba(0,0,0,0.6)",
        backdropFilter: "blur(8px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
        padding: "20px",
      }}
    >
      <div
        ref={modalRef}
        onClick={(e) => e.stopPropagation()}
        style={{
          background: "#111",
          color: "#fff",
          borderRadius: "12px",
          width: "100%",
          maxWidth: "960px",
          maxHeight: "90vh",
          display: "flex",
          flexDirection: "column",
          overflow: "hidden",
          border: "1px solid rgba(255,255,255,0.15)",
        }}
      >
        {/* Modal Header */}
        <ModalHeader title="Edit Product" onClose={onClose} />

        {/* Modal Body */}
        <div
          style={{
            padding: "1.5rem",
            overflowY: "auto",
            flexGrow: 1,
          }}
        >
          <ProductIdEditor
            productId={productId}
            setProductId={setProductId}
            title={title}
            autoGenerateId={autoGenerateId}
            setAutoGenerateId={setAutoGenerateId}
          />

          <ProductEditor
            title={title}
            setTitle={setTitle}
            defaultPrice={defaultPrice}
            setDefaultPrice={setDefaultPrice}
            defaultShippingInfo={defaultShippingInfo}
            setDefaultShippingInfo={setDefaultShippingInfo}
            description={description}
            setDescription={setDescription}
            designedFor={designedFor}
            setDesignedFor={setDesignedFor}
            instructions={instructions}
            setInstructions={setInstructions}
            productVideo={productVideo}
            setProductVideo={setProductVideo}
            releaseDate={releaseDate}
            setReleaseDate={setReleaseDate}
            category={category}
            setCategory={setCategory}
          />

          <h3>Linked Images</h3>
          {photos.length === 0 && (
            <p
              style={{ color: "#aaa", fontSize: "13px", marginBottom: "1rem" }}
            >
              No images linked to this product yet.
            </p>
          )}
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(auto-fit, minmax(160px, 1fr))",
              gap: "16px",
              marginBottom: "2rem",
            }}
          >
            {photos.map((filename) => {
              const image = {
                filename,
                url: `${CDN_BASE_URL}${filename}`,
                size: 0,
              };
              return (
                <S3ImageGridItem
                  key={filename}
                  image={image}
                  linkedOverride={true}
                  onLink={(img, isLinked) => {
                    if (isLinked) {
                      setPhotos((prev) =>
                        prev.filter((f) => f !== img.filename)
                      );
                    }
                  }}
                />
              );
            })}
            <div
              onClick={() => setShowImagePicker(true)}
              style={{
                width: "100%",
                height: "160px",
                border: "2px dashed rgba(255,255,255,0.3)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#1a1a1a",
              }}
            >
              <span
                style={{ color: "rgba(255,255,255,0.7)", fontSize: "24px" }}
              >
                +
              </span>
            </div>
          </div>

          <h3>SKUs</h3>
          <SkuMaker
            skus={skus}
            handleSkuChange={handleSkuChange}
            removeSku={removeSku}
            duplicateSku={duplicateSku}
            skuErrors={skuErrors}
          />
        </div>

        {/* Modal Footer */}
        <ModalFooter>
          <CancelButton onClick={onClose} />
          <SaveButton onClick={handleSave} disabled={submitting}>
            {submitting ? "Saving..." : "Save"}
          </SaveButton>
        </ModalFooter>

        {/* Image Picker Modal */}
        {showImagePicker && (
          <ImagePickerModal
            images={s3ImagesStore.images}
            onSelect={handleImageSelect}
            onClose={() => setShowImagePicker(false)}
          />
        )}
      </div>
    </div>
  );
};

export default ProductEditModal;
