import React from "react";
import ProductCard from "./ProductCard";

const ProductRow = ({ products }) => {
  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "95%",
        maxWidth: "900px",
      }}
    >
      {products.map((product) => (
        <div
          key={product.id}
          style={{
            flex: "1 1 250px", // Default size for larger screens
            maxWidth: "300px", // Prevents items from stretching too much
            minWidth: "150px", // Ensures two items per row on mobile
            marginBottom: "30px", // Ensure space between wrapped rows
          }}
        >
          <ProductCard product={product} />
        </div>
      ))}
    </div>
  );
};

export default ProductRow;
