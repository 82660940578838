import { makeAutoObservable } from "mobx";

class UIStore {
  globalLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  setGlobalLoading(val) {
    this.globalLoading = val;
  }
}

const uiStore = new UIStore();
export default uiStore;
