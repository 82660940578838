import React from "react";
import { IoLinkOutline, IoUnlinkOutline } from "react-icons/io5";
import PulseButton from "./PulseButton";

const ManagerLinkButton = ({ isLinked, onClick }) => {
  const Icon = isLinked ? IoUnlinkOutline : IoLinkOutline;

  return (
    <PulseButton onClick={onClick} title={isLinked ? "Unlink" : "Link"}>
      {isLinked ? "Unlink" : "Link"}
      <Icon size={18} />
    </PulseButton>
  );
};

export default ManagerLinkButton;
