import React from "react";
import { IoTrash } from "react-icons/io5";
import PulseButton from "./PulseButton";

const DeleteButton = ({ onClick, disabled, compact }) => (
  <PulseButton
    onClick={onClick}
    title="Delete"
    style={{
      backgroundColor: "rgba(239, 68, 68, 0.85)",
      color: "white",
      border: "none",
      padding: "8px 12px",
      borderRadius: "6px",
      fontSize: compact ? "0" : "12px",
      fontWeight: "bold",
      cursor: "pointer",
      backdropFilter: "blur(4px)",
      boxShadow: "0 2px 6px rgba(0,0,0,0.2)",
      transition: "all 0.2s ease-in-out",
      // display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }}
    onMouseEnter={(e) => {
      e.currentTarget.style.backgroundColor = "#dc2626";
    }}
    onMouseLeave={(e) => {
      e.currentTarget.style.backgroundColor = "rgba(239, 68, 68, 0.85)";
    }}
    disabled={disabled}
  >
    {compact ? <IoTrash size={14} /> : "DELETE"}
  </PulseButton>
);

export default DeleteButton;
