import React, { useState } from "react";
import ProductInfoModal from "./ProductInfoModal";
import SkuSelector from "../../SkuSelector";
import { getProductImageSrc } from "../../utilities/imageUtils";

const ProductPreview = ({ product }) => {
  const [selectedSku, setSelectedSku] = useState(product.skus[0]);
  const [isModalOpen, setModalOpen] = useState(false);

  const handleSkuChange = (skuId) => {
    const sku = product.skus.find((s) => s.id === skuId);
    setSelectedSku(sku);
  };
  const imageSrc = getProductImageSrc({
    skuImage: selectedSku.image,
    productPhotos: product.product_photos,
  });
  return (
    <>
      <div
        onClick={() => setModalOpen(true)}
        style={{
          display: "flex",
          alignItems: "center",
          gap: "1rem",
          background: "rgba(255,255,255,0.05)",
          padding: "1rem",
          borderRadius: "12px",
          border: "1px solid rgba(255,255,255,0.2)",
          cursor: "pointer",
          width: "100%" - 20,
          maxHeight: "120px",
        }}
      >
        <img
          src={`${imageSrc}`}
          alt={product.title}
          style={{
            height: "80px",
            width: "80px",
            objectFit: "cover",
            borderRadius: "6px",
            flexShrink: 0,
          }}
        />

        <div style={{ flex: 1 }}>
          <h4
            style={{
              fontSize: "15px",
              fontWeight: "bold",
              color: "white",
              margin: 0,
              marginBottom: "0.5rem",
              lineHeight: "1.2",
            }}
          >
            {product.title}
          </h4>

          <SkuSelector
            skus={product.skus}
            selectedSku={selectedSku}
            onSkuChange={handleSkuChange}
          />
        </div>
      </div>

      <ProductInfoModal
        product={product}
        selectedSku={selectedSku}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        hideAddToCart
      />
    </>
  );
};

export default ProductPreview;
