import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import cartStore from "../../stores/CartStore";
import CartModal from "./CartModal";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import "../../global.css";

const CartIcon = observer(() => {
  const [showCart, setShowCart] = useState(false);
  const [animateAdd, setAnimateAdd] = useState(false);
  const [remindPulse, setRemindPulse] = useState(false);
  const [prevTotal, setPrevTotal] = useState(cartStore.totalItems);

  // Animate when item is added
  useEffect(() => {
    if (cartStore.totalItems > prevTotal) {
      setAnimateAdd(true);
      setTimeout(() => setAnimateAdd(false), 400); // end after animation
    }
    setPrevTotal(cartStore.totalItems);
  }, [cartStore.totalItems]);

  // Recurring shake/pulse every 30 seconds
  useEffect(() => {
    if (cartStore.totalItems === 0) return;

    const interval = setInterval(() => {
      setRemindPulse(true);
      setTimeout(() => setRemindPulse(false), 400); // animation reset
    }, 30000); // 30s

    return () => clearInterval(interval);
  }, [cartStore.totalItems]);

  const buttonClass = `cart-button ${
    animateAdd ? "cart-bump" : remindPulse ? "cart-remind" : ""
  }`;

  return (
    <>
      {cartStore.totalItems > 0 && (
        <button onClick={() => setShowCart(true)} className={buttonClass}>
          <PiShoppingCartSimpleBold size={18} />
          <span className="cart-badge">{cartStore.totalItems}</span>
        </button>
      )}

      {showCart && <CartModal onClose={() => setShowCart(false)} />}
    </>
  );
});

export default CartIcon;
