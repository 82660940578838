import React, { useState } from "react";
import Lightbox from "../Lightbox/Lightbox"; // Import the Lightbox component
import ProductInfoModal from "./ProductInfoModal"; // Import the Product Info Modal
import SkuSelector from "../../SkuSelector";
import StockInfo from "../../StockInfo";
import PriceInfo from "./PriceInfo";
import NewReleaseBadge from "../../NewReleaseBadge";
import SaleBadge from "../../SaleBadge";
import BuyButton from "../buttons/BuyButton";
import { getProductImageSrc } from "../../utilities/imageUtils";

const ProductCard = ({ product, hideAddToCart = false }) => {
  const [selectedSku, setSelectedSku] = useState(product.skus[0]);
  const [isLightboxOpen, setLightboxOpen] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);

  const cdnBaseUrl = "https://cdn.karsho.com/product_images/"; // Base URL for the CDN

  const handleSkuChange = (skuId) => {
    const sku = product.skus.find((s) => s.id === skuId);
    setSelectedSku(sku);
  };

  const handleCheckout = () => {
    // Redirect the user to the appropriate Stripe Payment Link
    const paymentLink = selectedSku.on_sale
      ? selectedSku.sale_payment_link
      : selectedSku.stripe_payment_link;
    window.location.href = paymentLink;
  };

  // Determine price, shipping info, and stock based on SKU or defaults
  const price = selectedSku.price || product.default_price;
  const SkuOnSale = selectedSku.on_sale || product.on_sale;
  const salePrice = selectedSku.on_sale ? selectedSku.sale_price : null;
  const shippingInfo =
    selectedSku.stock > 2 && selectedSku.made_on_order
      ? product.default_shipping_info
      : selectedSku.shipping_info || product.default_shipping_info;
  const stock = selectedSku.stock ?? product.default_stock; // Stock is optional in both SKU and product
  const madeOnOrder = selectedSku.made_on_order || false;

  // Determine stock status
  const stockText =
    madeOnOrder && stock < 3
      ? "Made on Order"
      : stock === 0 || stock == null
      ? "Out of Stock"
      : stock < 4
      ? `Low Stock`
      : "In Stock";
  const isOutOfStock = !madeOnOrder && (stock === 0 || stock == null);
  const imageSrc = getProductImageSrc({
    skuImage: selectedSku.image,
    productPhotos: product.product_photos,
  });
  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
          borderRadius: "15px",
          boxShadow: "0 8px 20px rgba(0, 0, 0, 0.3)",
          padding: "15px",
          border: "1px solid rgba(255, 255, 255, 0.2)",
          background: "rgba(0, 0, 0, 0.5)",
          backdropFilter: "blur(10px)",
          WebkitBackdropFilter: "blur(10px)",
          color: "#eaeaea",
          fontFamily: "Arial, sans-serif",
          textAlign: "center",
          position: "relative",
          cursor: "pointer", // Allow clicking anywhere to open modal
        }}
        className="product-card"
        onClick={() => setModalOpen(true)} // Open Product Info Modal
      >
        {/* Product Image */}
        <div
          style={{
            position: "relative",
            cursor: "pointer",
          }}
          onClick={(e) => {
            e.stopPropagation(); // Prevent opening modal when clicking image
            setLightboxOpen(true);
          }}
        >
          <img
            src={`${imageSrc}`}
            alt={product.title}
            style={{
              width: "100%",
              aspectRatio: "1 / 1",
              objectFit: "cover",
              borderRadius: "10px",
              verticalAlign: "bottom",
              transition: "all 0.3s ease-in-out",
            }}
            className="product-image"
          />
          {/* New Release Badge */}
          <NewReleaseBadge releaseDate={product.release_date} />

          {/* Sale Badge */}
          <SaleBadge isOnSale={SkuOnSale} />

          {/* Designed For Badge */}
          {product.designed_for && (
            <div
              style={{
                position: "absolute",
                bottom: "2px",
                left: "2px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
                color: "#fff",
                padding: "5px 10px",
                fontSize: "12px",
                borderRadius: "0px 10px 0px 9px",
                backdropFilter: "blur(5px)",
                WebkitBackdropFilter: "blur(5px)",
              }}
            >
              {product.designed_for}
            </div>
          )}
        </div>

        {/* Product Title */}
        <h3 style={{ fontSize: "18px", margin: "10px 0" }}>{product.title}</h3>

        {/* Stock Info */}
        <StockInfo stock={stock} madeOnOrder={madeOnOrder} />

        {/* Shipping Info */}
        <p
          style={{
            fontSize: "14px",
            color: "rgba(255, 255, 255, 0.8)",
            margin: "5px 0",
          }}
        >
          {shippingInfo}
        </p>

        {/* Price */}
        <PriceInfo price={price} salePrice={salePrice} />

        {/* SKU Selector */}
        <SkuSelector
          skus={product.skus}
          selectedSku={selectedSku}
          onSkuChange={handleSkuChange}
        />

        {/* Sticky Buttons */}
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          {!hideAddToCart && (
            <BuyButton selectedSku={selectedSku} product={product} type="add" />
          )}
          <BuyButton selectedSku={selectedSku} product={product} type="buy" />
        </div>
      </div>

      {/* Render Product Info Modal */}
      <ProductInfoModal
        product={product}
        selectedSku={selectedSku}
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        hideAddToCart={hideAddToCart}
      />

      {/* Render Lightbox */}
      {isLightboxOpen && (
        <Lightbox
          photos={product.product_photos.map(
            (photo) => `${cdnBaseUrl}${photo}` // Use CDN URL for lightbox photos
          )}
          onClose={() => setLightboxOpen(false)} // Close lightbox
        />
      )}
    </>
  );
};

export default ProductCard;
