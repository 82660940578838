const ProductSpecs = ({ activeSku, product }) => {
    return (
      <div>
        <h3>{activeSku.label} Specifications</h3>
        <p style={{ fontSize: "14px", opacity: 0.8 }}>
          {activeSku.sku_description || "No details provided."}
        </p>
        <ul style={{ listStyleType: "none", padding: 0, fontSize: "0.85rem" }}>
          <li>
            <strong>Designed For:</strong>{" "}
            {activeSku.sku_designed_for || product.designed_for || "Universal"}
          </li>
          <li>
            <strong>Material:</strong> {activeSku.material || "Unknown"}
          </li>
          <li>
            <strong>Weight:</strong> {activeSku.weight || "Not specified"}
          </li>
          <li>
            <strong>Hardware Included:</strong>{" "}
            {activeSku.hardware_included || "Not specified"}
          </li>
  
          {/* Product Instructions (Only Show if Exists) */}
          {product.instructions && (
            <li>
              <strong>Instructions:</strong> {product.instructions}
            </li>
          )}
  
          {/* Product Video (Only Show if Exists) */}
          {product.product_video && (
            <li>
              <strong>Product Video:</strong>{" "}
              <a
                href={product.product_video}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: "#a855f7", textDecoration: "underline" }}
              >
                Watch Video
              </a>
            </li>
          )}
        </ul>
  
        <h3>Shipping Info</h3>
        <p style={{ fontSize: "14px", opacity: 0.8 }}>
          {activeSku.shipping_info ||
            product.default_shipping_info ||
            "Standard shipping available."}
        </p>
      </div>
    );
  };
  
  export default ProductSpecs;
  