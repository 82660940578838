import React from "react";
import { IoSearch } from "react-icons/io5";

const SearchInput = ({ value, onChange, placeholder = "Search..." }) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        background: "rgba(255, 255, 255, 0.05)",
        border: "1px solid rgba(255, 255, 255, 0.2)",
        borderRadius: "6px",
        padding: "6px 10px",
        color: "white",
      }}
    >
      <IoSearch size={16} style={{ marginRight: "8px" }} />
      <input
        type="text"
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        style={{
          background: "transparent",
          border: "none",
          outline: "none",
          color: "white",
          fontSize: "14px",
          width: "100%",
        }}
      />
    </div>
  );
};

export default SearchInput;
