import React, { useEffect, useState } from "react";
import productsStore from "../../stores/ProductsStore";
import Dropdown from "../common/Dropdown";
import ProductPreview from "../Product/ProductPreview";
import CancelButton from "../buttons/CancelButton.js";
import SaveButton from "../buttons/SaveButton.js";
import PulseButton from "../buttons/PulseButton.js";
import {
  getAssociatedProduct,
  getImageLinkType,
  isImageLinked,
} from "../../utilities/imageUtils.js";

const ManagerLinkModal = ({ image, onClose, onSave }) => {
  const associatedProduct = getAssociatedProduct({
    filename: image.filename,
    products: productsStore.products,
  });

  const initialProductId = image.product_id || associatedProduct?.id || "";
  const initialSkuIds = image.sku_ids || [];

  const [selectedProductId, setSelectedProductId] = useState(initialProductId);
  const [selectedSkuIds, setSelectedSkuIds] = useState(initialSkuIds);
  const [isManuallyUnlinked, setIsManuallyUnlinked] = useState(false);

  useEffect(() => {
    productsStore.loadProducts();
  }, []);

  const selectedProduct = productsStore.products.find(
    (p) => p.id === selectedProductId
  );

  const activeProduct = isManuallyUnlinked
    ? null
    : selectedProduct || associatedProduct || null;

  const isLinked = Boolean(
    selectedProductId ||
      selectedSkuIds.length ||
      (!isManuallyUnlinked && associatedProduct)
  );

  const linkType = isManuallyUnlinked
    ? null
    : getImageLinkType(image.filename, productsStore.products);

  const hasAnyAssociation = Boolean(
    selectedProductId || selectedSkuIds.length || associatedProduct
  );

  const skuOptions =
    activeProduct?.skus?.map((sku) => ({
      label: sku.label,
      value: sku.id,
    })) || [];

  const productOptions = productsStore.allProducts.map((product) => ({
    label: `${product.title} (${product.id})`,
    value: product.id,
  }));

  const toggleSku = (skuId) => {
    setSelectedSkuIds((prev) =>
      prev.includes(skuId)
        ? prev.filter((id) => id !== skuId)
        : [...prev, skuId]
    );
  };

  const handleSubmit = () => {
    const payload = {
      filename: image.filename,
      product_id: selectedProductId || null,
      sku_ids: selectedSkuIds || null,
      previous_product_id: initialProductId || null, // ✅ Pass the original
    };
  
    console.log("🛰 Submitting updated metadata:", payload);
  
    onSave(payload);
    onClose();
  };
  

  const arraysEqual = (a = [], b = []) =>
    a.length === b.length &&
    a.every((v) => b.includes(v)) &&
    b.every((v) => a.includes(v));

  const isUnchanged =
    selectedProductId === initialProductId &&
    arraysEqual(selectedSkuIds, initialSkuIds);

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.6)",
        backdropFilter: "blur(6px)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          background: "#111",
          color: "white",
          borderRadius: "10px",
          padding: "20px",
          width: "100%",
          maxWidth: "500px",
          maxHeight: "90vh",
          overflowY: "auto",
          boxShadow: "0 0 10px rgba(0,0,0,0.4)",
        }}
      >
        <h3 style={{ marginBottom: "1rem" }}>
          Update Associations for <code>{image.filename}</code>
        </h3>

        {/* Image Preview */}
        <div
          style={{
            marginBottom: "1rem",
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            src={image.url}
            alt={image.filename}
            style={{
              maxWidth: "80%",
              height: "auto",
              borderRadius: "10px",
              border: "1px solid rgba(255,255,255,0.2)",
              objectFit: "contain",
            }}
          />
        </div>

        {activeProduct && (
          <div style={{ marginBottom: "1rem" }}>
            <p style={{ fontSize: "14px", marginBottom: "0.5rem" }}>
              {selectedProduct
                ? "Selected product:"
                : "Currently associated product:"}
            </p>
            <ProductPreview product={activeProduct} />
          </div>
        )}

        {linkType && (
          <p
            style={{
              fontSize: "13px",
              color: "rgba(255,255,255,0.7)",
              marginTop: "0.5rem",
              textAlign: "center",
            }}
          >
            Currently used as: {linkType}
          </p>
        )}

        {isLinked && (
          <PulseButton
            onClick={() => {
              if (window.confirm("Unlink this image from all associations?")) {
                setSelectedProductId("");
                setSelectedSkuIds([]);
                setIsManuallyUnlinked(true);
              }
            }}
            style={{
              backgroundColor: "#ef4444",
              marginBottom: "1rem",
            }}
          >
            Unlink Image
          </PulseButton>
        )}

        {/* Product dropdown */}
        <div style={{ marginBottom: "1rem" }}>
          <Dropdown
            label="Select Product"
            options={productOptions}
            value={selectedProductId}
            onChange={(e) => {
              const newProductId = e.target.value;
              setSelectedProductId(newProductId);
              setIsManuallyUnlinked(false);

              const newProduct = productsStore.products.find(
                (p) => p.id === newProductId
              );

              const linkedSkus =
                newProduct?.skus
                  .filter((sku) => sku.image === image.filename)
                  .map((sku) => sku.id) || [];

              setSelectedSkuIds(linkedSkus);
            }}
            fullWidth
          />
        </div>

        {/* SKU selector */}
        {activeProduct && skuOptions.length > 0 && (
          <div style={{ marginBottom: "1rem" }}>
            <label>Associated SKUs:</label>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                gap: "0.5rem",
                marginTop: "0.5rem",
              }}
            >
              {skuOptions.map((sku) => (
                <button
                  key={sku.value}
                  onClick={() => toggleSku(sku.value)}
                  style={{
                    padding: "6px 10px",
                    borderRadius: "6px",
                    backgroundColor: selectedSkuIds.includes(sku.value)
                      ? "#8b5cf6"
                      : "rgba(255,255,255,0.1)",
                    border: "1px solid rgba(255,255,255,0.2)",
                    color: "white",
                    fontSize: "12px",
                    cursor: "pointer",
                  }}
                >
                  {sku.label}
                </button>
              ))}
            </div>
          </div>
        )}

        {/* Action buttons */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
        >
          <CancelButton onClick={onClose} />
          <SaveButton onClick={handleSubmit} disabled={isUnchanged} />
        </div>
      </div>
    </div>
  );
};

export default ManagerLinkModal;
