import React, { useState } from "react";
import cartStore from "../../stores/CartStore";
import { handleCheckout } from "../../utilities/checkoutUtils";
import uiStore from "../../stores/UIStore";
import PulseButton from "../buttons/PulseButton";

const BuyButton = ({
  selectedSku,
  product,
  type = "buy",
  addMargin = false,
}) => {
  const [loading, setLoading] = useState(false);

  const isOutOfStock =
    !selectedSku.made_on_order &&
    (selectedSku.stock === 0 || selectedSku.stock == null);

  const isDisabled = (isOutOfStock && type !== "order") || loading;

  const handleClick = async (e) => {
    e.stopPropagation();
    if (isDisabled) return;

    if (type === "buy") {
      try {
        setLoading(true);
        uiStore.setGlobalLoading(true);
        await handleCheckout({
          cartItems: [
            {
              productId: product.id,
              skuId: selectedSku.id,
              quantity: 1,
            },
          ],
        });
      } catch (error) {
        console.error("Checkout failed", error);
      } finally {
        setLoading(false);
        uiStore.setGlobalLoading(false);
      }
    }

    if (type === "add") {
      cartStore.addItem(selectedSku, product);
    }

    if (type === "order") {
      alert("Pre-order coming soon!");
    }
  };

  const buttonText = (() => {
    if (isOutOfStock) return "Out of Stock";
    if (type === "buy") return "Buy Now";
    if (type === "add") return "Add to Cart";
    if (type === "order") return "Pre-Order";
  })();

  const backgroundColor = (() => {
    if (isOutOfStock) return "rgba(255, 85, 85, 0.7)";
    if (type === "buy") return "rgba(168, 85, 247, 0.8)";
    if (type === "add") return "#10b981";
    if (type === "order") return "#f59e0b";
  })();

  return (
    <PulseButton
      disabled={isDisabled}
      onClick={handleClick}
      style={{
        backgroundColor,
        margin: addMargin ? "15px" : "0",
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "42px",
      }}
    >
      {loading ? (
        <div className="spinner" style={{ width: "20px", height: "20px" }} />
      ) : (
        buttonText
      )}
    </PulseButton>
  );
};

export default BuyButton;
