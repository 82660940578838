import React from "react";
import DeleteButton from "../buttons/DeleteButton";
import ManagerLinkButton from "../buttons/ManagerLinkButton";
import productsStore from "../../stores/ProductsStore";
import { isImageLinked } from "../../utilities/imageUtils";

const S3ImageManagerList = ({ images, onSelect, onDelete, onLink }) => {
  return (
    <ul style={{ listStyle: "none", padding: 0, margin: 0, width: "100%" }}>
      {images.map((imageObj) => {
        const linked = isImageLinked(imageObj.filename, productsStore.products);

        return (
          <li
            key={imageObj.filename}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              padding: "12px 16px",
              borderBottom: "1px solid rgba(255,255,255,0.1)",
              width: "100%",
              boxSizing: "border-box",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "1rem",
                flex: 1,
              }}
            >
              <img
                src={imageObj.url}
                alt={imageObj.filename}
                style={{
                  width: "60px",
                  height: "60px",
                  borderRadius: "6px",
                  cursor: "zoom-in",
                  objectFit: "cover",
                }}
                onClick={() => onSelect(imageObj)}
              />
              <div>
                <div
                  style={{
                    fontSize: "13px",
                    fontWeight: "bold",
                    wordBreak: "break-word",
                  }}
                >
                  {imageObj.filename}
                </div>
                <div style={{ fontSize: "11px", color: "#ccc" }}>
                  {(imageObj.size / 1024).toFixed(1)} KB •{" "}
                  {linked ? "Linked" : "Unlinked"}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", gap: "0.5rem" }}>
              <ManagerLinkButton isLinked={linked} onClick={() => onLink(imageObj)} />
              <span
  title={linked ? "Image is in use and cannot be deleted." : ""}
  style={{ display: "inline-flex" }} // ensures layout is preserved
>
  <DeleteButton
    onClick={() => onDelete(imageObj)}
    disabled={linked}
  />
</span>

            </div>
          </li>
        );
      })}
    </ul>
  );
};

export default S3ImageManagerList;
