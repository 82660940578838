// utils/imageUtils.js

import productsStore from "../stores/ProductsStore";

export function isImageLinked(imageFilename, products) {
  return products.some((product) => {
    const inProductPhotos = product.product_photos?.includes(imageFilename);
    const inSkuImages = product.skus?.some(
      (sku) => sku.image === imageFilename
    );
    return inProductPhotos || inSkuImages;
  });
}
export const getAssociatedProduct = ({ filename, products }) =>
  products.find(
    (product) =>
      product.product_photos?.includes(filename) ||
      product.skus?.some((sku) => sku.image === filename)
  );

export const getImageLinkType = (filename, products = []) => {
  for (const product of products) {
    if (product.product_photos?.includes(filename)) {
      return "Product Image";
    }
    if (product.skus?.some((sku) => sku.image === filename)) {
      return "SKU Image";
    }
  }
  return null;
};
export const getProductImageSrc = ({
  skuImage,
  productPhotos = [],
  cdnBaseUrl = "https://cdn.karsho.com/product_images/",
  fallback = "/fallback.png",
}) => {
  if (skuImage) return `${cdnBaseUrl}${skuImage}`;

  if (productPhotos.length > 0) {
    const randomPhoto =
      productPhotos[Math.floor(Math.random() * productPhotos.length)];
    return `${cdnBaseUrl}${randomPhoto}`;
  }

  return fallback;
};
