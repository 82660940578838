import React, { useState } from "react";
import CancelButton from "../buttons/CancelButton";
import { IoAdd } from "react-icons/io5";
import PulseButton from "../buttons/PulseButton";

const ManagerUploadModal = ({ onClose, onUploadSuccess }) => {
  const [file, setFile] = useState(null);
  const [productId, setProductId] = useState("");
  const [skuIds, setSkuIds] = useState("");
  const [uploading, setUploading] = useState(false);

  const handleUpload = async () => {
    if (!file) return alert("Please select an image file.");
    const formData = new FormData();
    formData.append("file", file);

    setUploading(true);
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/admin/images/upload`,
        {
          method: "POST",
          headers: {
            Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
          },
          body: formData,
        }
      );

      if (!res.ok) throw new Error("Upload failed");

      const data = await res.json();

      if (productId || skuIds) {
        const metadataRes = await fetch(
          `${process.env.REACT_APP_API_URL}/admin/images/metadata/${data.filename}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
            },
            body: JSON.stringify({
              product_id: productId || null,
              sku_ids: skuIds ? skuIds.split(",").map((id) => id.trim()) : [],
            }),
          }
        );

        if (!metadataRes.ok) {
          const errText = await metadataRes.text();
          throw new Error(`Metadata update failed: ${errText}`);
        }

        const metadataData = await metadataRes.json();
        data.product_id = metadataData.product_id;
        data.sku_ids = metadataData.sku_ids;
      }

      onUploadSuccess(data);
      onClose();
    } catch (err) {
      console.error(err);
      alert("Failed to upload image and metadata.");
    } finally {
      setUploading(false);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        backgroundColor: "rgba(0,0,0,0.6)",
        backdropFilter: "blur(6px)",
        zIndex: 1000,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={onClose}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          background: "#111",
          color: "white",
          borderRadius: "10px",
          padding: "20px",
          width: "100%",
          maxWidth: "500px",
          boxShadow: "0 0 10px rgba(0,0,0,0.4)",
        }}
      >
        <h3 style={{ marginBottom: "1.5rem" }}>Upload New Image</h3>

        {/* File picker area */}
        <div
          style={{
            marginBottom: "1.5rem",
            display: "flex",
            justifyContent: "center",
          }}
        >
          {!file ? (
            <label
              htmlFor="image-upload-input"
              style={{
                width: "150px",
                height: "150px",
                border: "2px dashed rgba(255,255,255,0.3)",
                borderRadius: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                backgroundColor: "#1a1a1a",
              }}
            >
              <IoAdd size={40} color="rgba(255,255,255,0.7)" />
              <input
                id="image-upload-input"
                type="file"
                accept="image/*"
                onChange={(e) => setFile(e.target.files[0])}
                style={{ display: "none" }}
              />
            </label>
          ) : (
            <img
              src={URL.createObjectURL(file)}
              alt="Preview"
              style={{
                width: "150px",
                height: "150px",
                borderRadius: "10px",
                objectFit: "cover",
                border: "1px solid rgba(255,255,255,0.2)",
              }}
            />
          )}
        </div>

        {/* Actions */}
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "1rem" }}
        >
          <CancelButton onClick={onClose} />
          {file && (
            <PulseButton onClick={handleUpload} disabled={uploading} compact style={{backgroundColor:"green"}}>
              {uploading ? "Uploading..." : "Upload"}
            </PulseButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default ManagerUploadModal;
