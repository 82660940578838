import React, { useState, useRef, useEffect } from "react";
import { IoChevronDown, IoChevronUp } from "react-icons/io5";

const Dropdown = ({
  label,
  options,
  value,
  onChange,
  fullWidth = false,
  dropLeft = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const selected = options.find((opt) => opt.value === value);

  // Close on outside click
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <div
      ref={dropdownRef}
      style={{
        position: "relative",
        color: "white",
        width: fullWidth ? "100%" : "auto",
      }}
    >
      <button
        onClick={() => setIsOpen((prev) => !prev)}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: fullWidth ? "100%" : "auto",
          minWidth: "160px",
          backgroundColor: "rgba(255,255,255,0.05)",
          border: "1px solid rgba(255,255,255,0.2)",
          padding: "8px 12px",
          borderRadius: "6px",
          cursor: "pointer",
          gap: "0.5rem",
          color: "white",
          fontWeight: "bold",
          textTransform: "uppercase",
        }}
      >
        <span>{selected?.label || label}</span>
        {isOpen ? <IoChevronUp size={16} /> : <IoChevronDown size={16} />}
      </button>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            [dropLeft ? "left" : "right"]: 0,
            marginTop: "4px",
            backgroundColor: "#111",
            borderRadius: "6px",
            border: "1px solid rgba(255,255,255,0.2)",
            boxShadow: "0 4px 10px rgba(0,0,0,0.3)",
            zIndex: 10,
            minWidth: "150px",
          }}
        >
          {options.map((opt) => (
            <div
              key={opt.value}
              onClick={() => {
                onChange({ target: { value: opt.value } });
                setIsOpen(false);
              }}
              style={{
                padding: "10px 12px",
                cursor: "pointer",
                textAlign: "left",
                fontWeight: value === opt.value ? "bold" : "normal",
                background: value === opt.value ? "#333" : "transparent",
                color: "white",
              }}
            >
              {opt.label}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Dropdown;
