// App.js
import React, { useState } from "react";
import Navbar from "./Navbar";
import CountdownTimer from "./CountdownTimer";
import MainView from "./MainView";
import Footer from "./Footer";
import {
  ALL_PRODUCTS_CATEGORY,
  BLACK_FRIDAY_TARGET_DATE,
} from "./utilities/constants";
import GlobalLoadingOverlay from "./components/GlobalLoadingOverlay";
import S3ImageManager from "./components/S3ImageManager/S3ImageManager";
import ProductManager from "./components/ProductManager/ProductManager";
import { ToastContainer } from "react-toastify";
import MaintenanceLanding from "./components/MaintenanceLanding";

const App = () => {
  const [activeItem, setActiveItem] = useState(ALL_PRODUCTS_CATEGORY);

  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "black",
        color: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: "0px 0px",
        gap: "40px",
      }}
    >
      <MaintenanceLanding />
    </div>
  );
};

export default App;
