// stores/CartStore.js
import { makeAutoObservable, reaction, runInAction } from "mobx";

const STORAGE_KEY = "vk_cart";

class CartStore {
  items = [];

  constructor() {
    makeAutoObservable(this);

    // Load from localStorage if exists
    const saved = localStorage.getItem(STORAGE_KEY);
    if (saved) {
      try {
        const parsed = JSON.parse(saved);
        if (Array.isArray(parsed)) {
          runInAction(() => {
            this.items = parsed;
          });
        }
      } catch (e) {
        console.error("Failed to load saved cart:", e);
      }
    }

    // Save changes to localStorage when items change
    reaction(
      () => this.items.map((item) => ({ skuId: item.skuId, quantity: item.quantity })), // shallow watch
      () => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(this.items));
      }
    );
  }

  addItem(sku, product) {
    const existing = this.items.find((item) => item.skuId === sku.id);
    if (existing) {
      existing.quantity += 1;
    } else {
      this.items.push({
        skuId: sku.id,
        productId: product.id,
        quantity: 1,
        skuData: sku,
        productData: product,
      });
    }
  }

  removeItem(skuId) {
    this.items = this.items.filter((item) => item.skuId !== skuId);
  }

  updateQuantity(skuId, newQty) {
    const item = this.items.find((item) => item.skuId === skuId);
    if (item) item.quantity = newQty;
  }

  clearCart() {
    this.items = [];
  }

  get totalItems() {
    return this.items.reduce((acc, item) => acc + item.quantity, 0);
  }

  get totalPrice() {
    return this.items.reduce((acc, item) => {
      const price =
        item.skuData.on_sale && item.skuData.sale_price
          ? item.skuData.sale_price
          : item.skuData.price;
      return acc + price * item.quantity;
    }, 0);
  }
}

const cartStore = new CartStore();
export default cartStore;
