import React, { useState } from "react";
import faqPods from "./faqs/faq_pods.json"; // Import FAQ data for pods
import faqIntakeMods from "./faqs/faq_intake_mods.json"; // Import FAQ data for pods
import faqWideMouthIntake from "./faqs/faq_wide_mouth_intake.json"
import faqSupaloud from "./faqs/faq_supaloud.json"

const FAQ = ({ source = "general" }) => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  // Add logic to load different FAQs based on source
  const faqDataMap = {
    "pods": faqPods,
    "intake-mods": faqIntakeMods,
    "wide-mouth-intake": faqWideMouthIntake,
    "supaloud-intake-kit": faqSupaloud,
  };

  const faqData = faqDataMap[source] || [];

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  return (
    <div id="faq" style={{ padding: "20px 20px 40px 20px", textAlign: "center" }}>
      <h2 style={{ fontSize: "2rem", fontWeight: "bold", marginBottom: "10px" }}>FAQ</h2>
      <h3 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "20px" }}>
        Here are the answers to the most frequently asked questions
      </h3>
      <p style={{ marginBottom: "40px", color: "gray" }}>
        No convoluted T&Cs. Our FAQ section is our T&C.
      </p>
      <div style={{ maxWidth: "600px", margin: "0 auto", textAlign: "left" }}>
        {faqData.map((item, index) => (
          <div
            key={index}
            style={{
              border: "1px solid rgba(255, 255, 255, 0.2)", 
              borderRadius: "10px",
              marginBottom: "10px",
              overflow: "hidden",
              boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
            }}
          >
            <div
              onClick={() => toggleExpand(index)}
              style={{
                padding: "15px 20px",
                backgroundColor: "transparent",
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: "1rem", fontWeight: "bold" }}>{item.question}</span>
              <span style={{ fontSize: "1.5rem", transform: expandedIndex === index ? "rotate(180deg)" : "rotate(0)", transition: "transform 0.2s" }}>
                &#x25B4; {/* Upward arrow */}
              </span>
            </div>
            {expandedIndex === index && (
              <div style={{ padding: "15px 20px", backgroundColor: "#fff", color: "gray" }}>
                {item.answer}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
