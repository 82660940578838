import React from "react";
import Dropdown from "../common/Dropdown";
import ToggleButton from "../buttons/ToggleButton";
import { iconMapping } from "../../utilities/iconMapping";
import SearchInput from "../inputs/SearchInput";
import { IoAdd } from "react-icons/io5";
import PulseButton from "../buttons/PulseButton";

const S3ImageManagerHeader = ({
  images,
  sortBy,
  setSortBy,
  viewMode,
  setViewMode,
  searchQuery,
  setSearchQuery,
  onUploadClick,
}) => {
  const totalSizeMB = (
    images.reduce((sum, img) => sum + (img.size || 0), 0) /
    1024 /
    1024
  ).toFixed(2);

  const IconPackage = iconMapping.package;

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "0.5rem",
          marginBottom: "1rem",
        }}
      >
        <IconPackage size={24} />
        <h2 style={{ margin: 0 }}>S3 Image Manager</h2>
      </div>

      <div
        style={{
          marginBottom: "1rem",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>
          <strong>Total:</strong> {images.length} images,{" "}
          <strong>{totalSizeMB} MB</strong>
        </div>

        <div style={{ display: "flex", gap: "1rem", alignItems: "center" }}>
          <SearchInput
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Filter by product or SKU ID"
          />

          <Dropdown
            label="Sort by"
            options={[
              { label: "Filename", value: "name" },
              { label: "File Size", value: "size" },
              { label: "Used / Unused", value: "usage" },
            ]}
            value={sortBy}
            onChange={(e) => setSortBy(e.target.value)}
          />

          <ToggleButton
            icon={viewMode === "grid" ? "list" : "grid"}
            onClick={() => setViewMode(viewMode === "grid" ? "list" : "grid")}
          />

          <PulseButton
            onClick={onUploadClick}
            style={{
              display: "flex",
              alignItems: "center",
              gap: "0.25rem",
              cursor: "pointer",
            }}
            compact
          >
            <IoAdd size={20} />
            UPLOAD
          </PulseButton>
        </div>
      </div>
    </div>
  );
};

export default S3ImageManagerHeader;
