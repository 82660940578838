import React from "react";

const PriceInfo = ({ price, salePrice }) => {
  return (
    <p style={{ fontSize: "18px", fontWeight: "bold", color: "#fff"}}>
      {salePrice ? (
        <>
          <span
            style={{
              textDecoration: "line-through",
              color: "rgba(255, 255, 255, 0.6)",
              fontSize: "16px",
              marginRight: "8px",
            }}
          >
            ${price}
          </span>
          <span style={{ color: "#f0c14b" }}>${salePrice}</span>
        </>
      ) : (
        `$${price}`
      )}
    </p>
  );
};

export default PriceInfo;
