import { getCategory } from "./categoryUtils";
import { ALL_PRODUCTS_CATEGORY } from "./constants";
import productsStore from "../stores/ProductsStore"; // use MobX store

export const getProductList = async ({ category, products, refresh = false } = {}) => {
  await productsStore.loadProducts(refresh); // load or refresh products
  const allProducts = productsStore.products;

  if (!allProducts?.length) return [];

  if (category === ALL_PRODUCTS_CATEGORY) {
    return [...allProducts].sort(
      (a, b) => new Date(b.release_date) - new Date(a.release_date)
    );
  }

  const matchedCategory = getCategory(category);
  if (!matchedCategory.name) return [];

  const mainCategory = matchedCategory.name.toLowerCase().replace(/\s+/g, "-");
  const altCategories = matchedCategory.alt_names.map((name) =>
    name.toLowerCase()
  );

  const filteredProducts = allProducts.filter((product) => {
    const productCategories = product.category.map((cat) =>
      cat.toLowerCase().replace(/\s+/g, "-")
    );

    return (
      productCategories.includes(mainCategory) ||
      productCategories.some((cat) => altCategories.includes(cat)) ||
      (products && products.includes(product.id))
    );
  });

  return filteredProducts.sort(
    (a, b) => new Date(b.release_date) - new Date(a.release_date)
  );
};
export const duplicateSkuAtIndex = (skus, index) => {
  if (!Array.isArray(skus) || index < 0 || index >= skus.length) return skus;
  const newSku = { ...skus[index] };
  return [...skus, newSku];
};
