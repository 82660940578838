import React, { useState } from "react";

const PulseButton = ({
  onClick,
  children,
  disabled = false,
  style = {},
  className = "",
  type = "button",
  compact = false,
}) => {
  const [pulse, setPulse] = useState(false);

  const handleClick = (e) => {
    if (disabled) return;
    setPulse(true);
    onClick?.(e);
    setTimeout(() => setPulse(false), 400);
  };

  return (
    <button
      type={type}
      disabled={disabled}
      onClick={handleClick}
      className={`${pulse ? "pulse-animation" : ""} ${className}`}
      style={{
        padding: compact ? "6px 10px" : "10px 14px",
        fontSize: "14px",
        fontWeight: "bold",
        border: compact
          ? "1px solid rgba(255, 255, 255, 0.2)"
          : "none",
        borderRadius: "6px",
        cursor: disabled ? "not-allowed" : "pointer",
        transition: "transform 0.2s ease-in-out, background-color 0.2s",
        backgroundColor: compact
          ? "rgba(255, 255, 255, 0.08)"
          : disabled
          ? "rgba(255, 255, 255, 0.1)"
          : "#333",
        color: disabled ? "rgba(255, 255, 255, 0.4)" : "white",
        opacity: disabled ? 0.6 : 1,
        ...style,
      }}
    >
      {children}
    </button>
  );
};

export default PulseButton;
