// categoryUtils.js
import vkSpecPods from "../categories/pods.json";
import intakeMods from "../categories/intake-mods.json";
import aeroMods from "../categories/aero-mods.json";
import wideMouthIntake from "../categories/wide-mouth-intake.json";
import supaloudIntakeKit from "../categories/supaloud-intake-kit.json";

const categories = {
  "all-products": {
    name: "All Products",
    alt_names: ["vk-spec", "all-products", "everything"],
  },
  "vk-spec-pods": vkSpecPods,
  "intake-mods": intakeMods,
  "aero-mods": aeroMods,
  "wide-mouth-intake": wideMouthIntake,
  "supaloud-intake-kit": supaloudIntakeKit,
};

/**
 * Retrieves a category object by matching its name or alternative names.
 * @param {string} categoryName - The category name to search for.
 * @returns {Object} The matching category object or an empty object if not found.
 */
export const getCategory = (categoryName) => {
  if (!categoryName) return {};

  const normalized = categoryName.toLowerCase().replace(/\s+/g, "-");

  return (
    Object.values(categories).find(
      (cat) =>
        cat.name.toLowerCase().replace(/\s+/g, "-") === normalized ||
        (cat.alt_names && cat.alt_names.some(
          (alt) => alt.toLowerCase().replace(/\s+/g, "-") === normalized
        ))
    ) || {}
  );
};
