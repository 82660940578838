import React from "react";

const StockInfo = ({ stock, madeOnOrder }) => {
  const stockText =
    madeOnOrder && stock < 3
      ? "Made on Order"
      : stock === 0 || stock == null
      ? "Out of Stock"
      : stock < 4
      ? "Low Stock"
      : "In Stock";

  const stockColor =
    madeOnOrder && stock < 3
      ? "orange"
      : stock === 0 || stock == null
      ? "red"
      : "lightgreen";

  return (
    <p style={{ fontSize: "14px", color: stockColor, fontWeight: "bold", marginBottom: "0px" }}>
      {stockText}
    </p>
  );
};

export default StockInfo;
