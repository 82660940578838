import productsStore from "../stores/ProductsStore";
import { getProductList } from "./productUtils";

// utilities/productAPI.js
export const fetchProducts = async () => {
  const url = `${process.env.REACT_APP_API_URL}/products`;

  try {
    const res = await fetch(url, {
      headers: {
        Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
      },
    });

    if (!res.ok) {
      console.error("[API Error] Failed to fetch products:", res.status);
      return [];
    }

    const data = await res.json();
    return data;
  } catch (err) {
    console.error("[Network Error] Could not fetch products:", err);
    return [];
  }
};
export const fetchProductById = async (productId) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/products/${productId}`
  );
  if (!res.ok) return null;
  return await res.json();
};

export const fetchSkuById = async (skuId) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/skus/${skuId}`);
  if (!res.ok) return null;
  return await res.json();
};
export const createProduct = async (productData) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/products/create`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
    },
    body: JSON.stringify(productData),
  });

  if (!res.ok) {
    const error = await res.json();
    throw new Error(error.detail || "Failed to create product");
  }
  await productsStore.loadProducts(true); // Force refresh after create


  return res.json();
};

export const deleteProduct = async (productId) => {
  const res = await fetch(
    `${process.env.REACT_APP_API_URL}/products/${productId}`,
    {
      method: "DELETE",
      headers: {
        Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
      },
    }
  );

  if (!res.ok) throw new Error("Failed to delete product");
  await productsStore.loadProducts(true); // Force refresh after create


  return res.json();
};
export const updateProductById = async (productId, productData) => {
  const res = await fetch(`${process.env.REACT_APP_API_URL}/products/${productId}`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
    },
    body: JSON.stringify(productData),
  });

  const json = await res.json();

  if (!res.ok) {
    throw json; // ✅ important: throw the parsed object directly
  }

  await productsStore.loadProducts(true); // 🔄 refresh cache
  return json;
};
