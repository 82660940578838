import React from "react";
import { observer } from "mobx-react-lite";
import cartStore from "../../stores/CartStore";
import { PiTrashSimple } from "react-icons/pi";

const CartItemList = observer(() => {
  const cdnBaseUrl = "https://cdn.karsho.com/product_images/";

  return (
    <ul style={{ listStyle: "none", padding: 0, marginBottom: "16px" }}>
      {cartStore.items.map((item) => (
        <li
          key={item.skuId}
          style={{
            display: "flex",
            alignItems: "center",
            gap: "12px",
            marginBottom: "18px",
          }}
        >
          <img
            src={`${cdnBaseUrl}${item.skuData.image}`}
            alt={item.skuData.label}
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "10px",
              objectFit: "cover",
              flexShrink: 0,
            }}
          />
          <div style={{ flexGrow: 1 }}>
            <div style={{ fontWeight: "600" }}>{item.skuData.label}</div>
            <div style={{ fontSize: "13px", opacity: 0.7 }}>
              {item.productData.title}
            </div>
            <div style={{ fontSize: "14px", marginTop: "4px" }}>
              ${item.skuData.price.toFixed(2)}
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
              <button
                onClick={() => cartStore.decreaseQuantity(item.skuId)}
                style={buttonStyle}
              >
                −
              </button>
              <span>{item.quantity}</span>
              <button
                onClick={() => cartStore.increaseQuantity(item.skuId)}
                style={buttonStyle}
              >
                +
              </button>
            </div>
            <button
              onClick={() => cartStore.removeItem(item.skuId)}
              title="Remove item"
              style={removeStyle}
            >
              <PiTrashSimple size={18} />
            </button>
          </div>
        </li>
      ))}
    </ul>
  );
});

const buttonStyle = {
  width: "28px",
  height: "28px",
  borderRadius: "50%",
  background: "rgba(255,255,255,0.1)",
  color: "white",
  border: "none",
  fontSize: "16px",
  cursor: "pointer",
};

const removeStyle = {
  marginTop: "6px",
  background: "transparent",
  border: "none",
  color: "#f87171",
  cursor: "pointer",
};

export default CartItemList;
