import React from "react";

const SaleBadge = ({ isOnSale, inline = false }) => {
  if (!isOnSale) return null;

  return (
    <div
      style={{
        backgroundColor: "#ff4747",
        color: "#fff",
        padding: "5px 10px",
        fontSize: "12px",
        borderRadius: inline ? "5px" : "0px 8px 0px 8px",
        fontWeight: "bold",
        zIndex: 2,
        ...(inline
          ? { position: "relative", display: "inline-block" } // Inline styles
          : { position: "absolute", top: "2px", right: "2px" }), // Absolute styles
      }}
    >
      Sale
    </div>
  );
};

export default SaleBadge;
