import React from "react";

const MaintenanceLanding = () => {
  return (
    <div
      style={{
        minHeight: "100vh",
        backgroundColor: "#000",
        color: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "space-between",
        textAlign: "center",
        padding: "2rem 1rem",
        gap: "2rem",
      }}
    >
      {/* Logo at Top */}
      <div style={{ marginTop: "1rem" }}>
        <img
          src="/logo.svg"
          alt="VK-SPEC"
          style={{ height: "100px", cursor: "pointer" }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </div>

      {/* Main Message */}
      <div>
        <h1 style={{ fontSize: "2.5rem",marginTop: "-2rem"  }}>Stay tuned.</h1>
        <p style={{ fontSize: "1.1rem", maxWidth: "600px", margin: "0 auto" }}>
          We’re upgrading behind the scenes to bring you something even better.
          The site will be back online soon with fresh drops and new features.
          In the meantime, enjoy some content from our channel.
        </p>
      </div>

      {/* YouTube Playlist Embed */}
      <div style={{ maxWidth: "90vw", width: "560px", aspectRatio: "16/9" }}>
        <iframe
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/videoseries?list=PLg4PDore956hZm5JkUSG0G9h2i0rMNndC"
          title="VK-SPEC Playlist"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>

      {/* Footer Links */}
      <footer
        style={{
          marginTop: "0rem",
          fontSize: "0.9rem",
          color: "#aaa",
          display: "flex",
          gap: "1.5rem",
          justifyContent: "center",
          flexWrap: "wrap",
        }}
      >
        <a
          href="https://www.youtube.com/@vividkoncepts"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#aaa", textDecoration: "none" }}
        >
          YouTube
        </a>
        <a
          href="https://www.instagram.com/vividkoncepts"
          target="_blank"
          rel="noopener noreferrer"
          style={{ color: "#aaa", textDecoration: "none" }}
        >
          Instagram
        </a>
        <a
          href="mailto:vividkonceptsdesign@gmail.com"
          style={{ color: "#aaa", textDecoration: "none" }}
        >
          Support
        </a>
      </footer>
    </div>
  );
};

export default MaintenanceLanding;
