import React from "react";
import PulseButton from "./PulseButton";

const SaveButton = ({ onClick }) => {
  return (
    <PulseButton
      onClick={onClick}
      style={{
        padding: "8px 12px",
        backgroundColor: "#22c55e",
        color: "white",
        border: "none",
        borderRadius: "6px",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Save</PulseButton>
  );
};

export default SaveButton;
