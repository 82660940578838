// GifPlayer.js
import React from "react";

const GifPlayer = ({ src }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      <img
        src={src}
        alt="Choose Your Spec Animation"
        style={{
          maxWidth: "100%",
          height: "auto",
          borderRadius: "10px",
        }}
      />
    </div>
  );
};

export default GifPlayer;