// src/stores/S3ImagesStore.js
import { makeAutoObservable, runInAction } from "mobx";

class S3ImagesStore {
  images = [];
  loading = false;

  constructor() {
    makeAutoObservable(this);
  }

  async fetchImages() {
    this.loading = true;
    try {
      const res = await fetch(`${process.env.REACT_APP_API_URL}/admin/images`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
        },
      });

      const data = await res.json();
      runInAction(() => {
        this.images = data.images || [];
      });
    } catch (err) {
      console.error("Failed to fetch S3 images", err);
    } finally {
      runInAction(() => {
        this.loading = false;
      });
    }
  }

  addImage(imageObj) {
    this.images.push(imageObj);
  }

  updateImageMetadata(filename, product_id, sku_ids) {
    const image = this.images.find((img) => img.filename === filename);
    if (image) {
      image.product_id = product_id;
      image.sku_ids = sku_ids;
    }
  }

  deleteImage(filename) {
    this.images = this.images.filter((img) => img.filename !== filename);
  }
}

const s3ImagesStore = new S3ImagesStore();
export default s3ImagesStore;
