import React from "react";

const NewReleaseBadge = ({ releaseDate, inline = false }) => {
  if (!releaseDate) return null;

  const releaseThreshold = 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds
  const releaseDateTime = new Date(releaseDate).getTime();
  const isNew = Date.now() - releaseDateTime <= releaseThreshold;

  if (!isNew) return null;

  return (
    <div
      style={{
        backgroundColor: "rgba(144, 238, 144, 1)",
        color: "#004400",
        padding: "5px 10px",
        fontSize: "12px",
        borderRadius: inline ? "5px" : "8px 0px 8px 0px",
        fontWeight: "bold",
        zIndex: 2,
        ...(inline
          ? { position: "relative", display: "inline-block" } // Inline styles
          : { position: "absolute", top: "2px", left: "2px" }), // Absolute styles
      }}
    >
      New Release
    </div>
  );
};

export default NewReleaseBadge;
