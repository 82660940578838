import React from "react";
import { observer } from "mobx-react-lite";
import uiStore from "../stores/UIStore";

const GlobalLoadingOverlay = observer(() => {
  if (!uiStore.globalLoading) return null;

  return (
    <div style={overlayStyle}>
      <div style={overlayContentStyle}>
        <div
          className="spinner"
          style={{ width: 40, height: 40, marginBottom: "12px" }}
        />
        <p style={{ color: "white", fontSize: "14px" }}>
          Redirecting to checkout...
        </p>
      </div>
    </div>
  );
});
const overlayStyle = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100vw",
  height: "100vh",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  backdropFilter: "blur(5px)",
  zIndex: 9999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const overlayContentStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
};

export default GlobalLoadingOverlay;
