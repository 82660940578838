import React, { useState, useEffect } from "react";
import { iconMapping } from "../../utilities/iconMapping";
import "../../global.css";
import PulseButton from "./PulseButton";

const ToggleButton = ({ icon, onClick }) => {
  const Icon = iconMapping[icon];
  const [animate, setAnimate] = useState(false);

  const handleClick = () => {
    setAnimate(true);
    onClick?.(); // optional chaining
  };

  useEffect(() => {
    if (animate) {
      const timeout = setTimeout(() => setAnimate(false), 400);
      return () => clearTimeout(timeout);
    }
  }, [animate]);

  return (
    <PulseButton
      onClick={handleClick}
      className={`toggle-button`}
      compact
    >
      <Icon size={18} color={'white'} />
      
    </PulseButton>
  );
};

export default ToggleButton;
