import React from "react";
import PulseButton from "./PulseButton";

const CancelButton = ({ onClick }) => {
  return (
    <PulseButton
      onClick={onClick}
      style={{
        padding: "8px 12px",
        backgroundColor: "transparent",
        color: "white",
        border: "1px solid rgba(255,255,255,0.2)",
        borderRadius: "6px",
        fontWeight: "bold",
        cursor: "pointer",
      }}
    >
      Cancel
    </PulseButton>
  );
};

export default CancelButton;
