import React, { useEffect, useState } from "react";
import SkuSelector from "../../SkuSelector";
import StockInfo from "../../StockInfo";
import PriceInfo from "./PriceInfo";
import NewReleaseBadge from "../../NewReleaseBadge";
import SaleBadge from "../../SaleBadge";
import BuyNowButton from "../buttons/BuyButton";
import ProductSpecs from "./ProductSpecs";
import BuyButton from "../buttons/BuyButton";
import { getProductImageSrc } from "../../utilities/imageUtils";

const ProductInfoModal = ({
  product,
  selectedSku,
  isOpen,
  onClose,
  hideAddToCart = false,
}) => {
  const [currentImage, setCurrentImage] = useState(selectedSku.image);
  const [activeSku, setActiveSku] = useState(selectedSku);
  const cdnBaseUrl = "https://cdn.karsho.com/product_images/";

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden"; // Disable background scrolling
    } else {
      document.body.style.overflow = ""; // Re-enable scrolling when modal closes
    }
    return () => {
      document.body.style.overflow = ""; // Cleanup on unmount
    };
  }, [isOpen]);

  const handleSkuChange = (skuId) => {
    const newSku = product.skus.find((s) => s.id === skuId);
    setActiveSku(newSku);
    setCurrentImage(newSku.image);
  };
  const imageSrc = getProductImageSrc({
    skuImage: selectedSku.image,
    productPhotos: product.product_photos,
  });
  if (!isOpen) return null;

  return (
    <div
      onClick={onClose}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        width: "100vw",
        height: "100vh",
        background: "rgba(0, 0, 0, 0.8)",
        backdropFilter: "blur(10px)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          width: "90%",
          maxWidth: "800px",
          background: "rgba(0, 0, 0, 0.95)",
          borderRadius: "15px",
          boxShadow: "0 8px 20px rgba(255, 255, 255, 0.2)",
          color: "#fff",
          overflow: "hidden",
          maxHeight: window.innerWidth <= 768 ? "80vh" : "90vh",
          marginTop: window.innerWidth <= 768 ? "-10vh" : "0", // Moves modal up on mobile
        }}
      >
        {/* Close Button - Positioned in the Top-Right Corner */}
        <button
          onClick={onClose}
          style={{
            position: "absolute",
            top: "10px",
            right: "10px",
            background: "rgba(255, 255, 255, 0.1)",
            border: "none",
            color: "#fff",
            fontSize: "20px",
            cursor: "pointer",
            padding: "5px 10px",
            borderRadius: "5px",
            zIndex: 10,
          }}
        >
          ✕
        </button>

        {/* Scrollable Content */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            padding: "0 20px 20px 20px",
            flexWrap: "wrap",
            overflowY: "auto",
          }}
        >
          {/* Main Product Image */}
          <div
            style={{
              paddingTop: "20px",
              flex: "1 1 300px",
              maxWidth: "300px",
              width: "100%",
            }}
          >
            <img
              src={`${imageSrc}`}
              alt={product.title}
              style={{
                width: "100%",
                aspectRatio: "1 / 1",
                objectFit: "cover",
                borderRadius: "10px",
              }}
            />

            {/* Scrollable Thumbnails */}
            {product.product_photos.length > 1 && (
              <div
                style={{
                  display: "flex",
                  overflowX: "auto",
                  gap: "10px",
                  marginTop: "10px",
                  padding: "8px", // Add padding for spacing
                  scrollbarWidth: "thin",
                  borderRadius: "10px", // Rounded corners for aesthetics
                  border: "1px solid rgba(255, 255, 255, 0.2)", // Thin border
                  background: "rgba(255, 255, 255, 0.05)", // Slightly lighter background color
                }}
              >
                {product.product_photos.map((photo, index) => (
                  <img
                    key={index}
                    src={`${cdnBaseUrl}${photo}`}
                    alt={`Thumbnail ${index + 1}`}
                    onClick={() => setCurrentImage(photo)}
                    style={{
                      width: "50px",
                      height: "50px",
                      objectFit: "cover",
                      borderRadius: "8px",
                      cursor: "pointer",
                      border:
                        currentImage === photo
                          ? "2px solid rgba(168, 85, 247, 0.8)"
                          : "2px solid rgba(255, 255, 255, 0.2)",
                      flexShrink: 0,
                      transition: "border 0.2s ease-in-out",
                    }}
                  />
                ))}
              </div>
            )}
          </div>

          {/* Product Details */}
          <div
            style={{
              flex: "1 1 300px",
              maxWidth: "400px",
              overflowY: "auto",
              maxHeight: "70vh",
            }}
          >
            <h2>{product.title}</h2>

            {/* Stock Info & Badges */}
            <div
              style={{
                display: "flex",
                alignItems: "baseline", // Forces vertical centering
                gap: "8px",
                height: "40px", // Static height applied
              }}
            >
              <StockInfo
                stock={activeSku.stock}
                madeOnOrder={activeSku.made_on_order}
              />
              <NewReleaseBadge
                releaseDate={product.release_date}
                inline={true}
              />
              <SaleBadge isOnSale={activeSku.on_sale} inline={true} />
            </div>

            <PriceInfo
              price={activeSku.price || product.default_price}
              salePrice={activeSku.onSale ? activeSku.sale_price : null}
            />

            <p style={{ fontSize: "14px", opacity: 0.8 }}>
              {product.description || "No description available."}
            </p>

            {/* SKU Selector */}
            <SkuSelector
              skus={product.skus}
              selectedSku={activeSku}
              onSkuChange={handleSkuChange}
            />
            <ProductSpecs activeSku={activeSku} product={product} />
          </div>
        </div>

        {!hideAddToCart && (
          <BuyButton selectedSku={selectedSku} product={product} type="add" />
        )}
        <BuyButton selectedSku={selectedSku} product={product} type="buy" />
      </div>
    </div>
  );
};

export default ProductInfoModal;
