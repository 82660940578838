import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe("your-publishable-key"); // Replace with your actual public key

export const handleCheckout = async ({ cartItems, discountCode = "", note = "" }) => {
  const stripe = await stripePromise;

  const response = await fetch(`${process.env.REACT_APP_API_URL}/checkout/session`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
    },
    body: JSON.stringify({
      items: cartItems.map((item) => ({
        sku_id: item.skuId,
        quantity: item.quantity,
      })),
      discount: discountCode,
      note,
    }),
  });

  const data = await response.json();

  if (response.ok && data.checkout_url) {
    window.location.href = data.checkout_url;
  } else {
    alert("Checkout failed.");
  }
};
