import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import ProductRow from "./components/Product/ProductRow";
import Features from "./Features";
import HeroSection from "./HeroSection";
import GifPlayer from "./GifPlayer";
import Heading from "./Heading";
import productsStore from "./stores/ProductsStore";
import { getCategory } from "./utilities/categoryUtils";
import FAQ from "./FAQ";
import "./global.css";

const MainView = observer(({ activeItem }) => {
  const category = getCategory(activeItem);

  useEffect(() => {
    productsStore.loadProducts(); // Only fetch once
  }, []);

  const products =
    activeItem === "VK-SPEC"
      ? productsStore.allProducts
      : productsStore.getProductsByCategory(activeItem);

  const loading = productsStore.loading;

  return (
    <>
      {category.show_hero && (
        <HeroSection
          title={category.name}
          subtitle={category.hero_subtitle}
          backgroundImage={category.hero_src}
        />
      )}

      {loading ? (
        <div style={{ padding: "2rem", textAlign: "center" }}>
          <div className="spinner" />
        </div>
      ) : (
        <>
          <ProductRow products={products} />
          {category.show_features && <Features />}
          {category.show_gif && (
            <>
              <GifPlayer src={category.gif_src} />
              <Heading text="Choose Your Spec" level="h4" />
              <ProductRow products={products} />
            </>
          )}
          {category.faqs && <FAQ source={category.faqs} />}
        </>
      )}
    </>
  );
});

export default MainView;
