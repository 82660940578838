// Heading.js
import React from "react";

const Heading = ({ text, level = "h4" }) => {
  const Tag = level;
  return (
    <Tag
      style={{
        fontSize: level === "h1" ? "2.5rem" :
                 level === "h2" ? "2rem" :
                 level === "h3" ? "1.75rem" :
                 level === "h4" ? "1.5rem" : "1.25rem",
        fontWeight: "bold",
        marginBottom: "0px",
      }}
    >
      {text}
    </Tag>
  );
};

export default Heading;
