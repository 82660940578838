import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import cartStore from "../../stores/CartStore";
import { IoClose } from "react-icons/io5";
import { PiShoppingCartSimpleBold } from "react-icons/pi";
import CartItemList from "./CartItemList";
import CartCheckout from "./CartCheckout";

const CartModal = observer(({ onClose }) => {
  const ref = useRef();

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        onClose();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [onClose]);

  return (
    <div
      style={{
        position: "fixed",
        inset: 0,
        background: "rgba(0, 0, 0, 0.6)",
        backdropFilter: "blur(6px)",
        WebkitBackdropFilter: "blur(6px)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
        padding: "16px",
      }}
    >
      <div
        ref={ref}
        style={{
          background: "rgba(17, 17, 17, 0.85)",
          border: "1px solid rgba(255, 255, 255, 0.15)",
          borderRadius: "20px",
          padding: "24px",
          width: "100%",
          maxWidth: "480px",
          maxHeight: "90vh",
          overflowY: "auto",
          color: "white",
          boxShadow: "0 8px 24px rgba(0,0,0,0.5)",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <h2
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "20px",
              fontWeight: "600",
              margin: 0,
            }}
          >
            <PiShoppingCartSimpleBold
              size={20}
              style={{ marginRight: "6px" }}
            />
            In Your Cart
          </h2>
          <button
            onClick={onClose}
            style={{
              background: "rgba(255,255,255,0.1)",
              border: "none",
              borderRadius: "50%",
              color: "white",
              padding: "6px",
              cursor: "pointer",
            }}
          >
            <IoClose size={18} />
          </button>
        </div>

        {cartStore.items.length === 0 ? (
          <p style={{ opacity: 0.8 }}>Your cart is empty.</p>
        ) : (
          <>
            <CartItemList />
            <CartCheckout onClose={onClose} />
          </>
        )}
      </div>
    </div>
  );
});

export default CartModal;
