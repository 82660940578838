import React, { useState } from "react";
import cartStore from "../../stores/CartStore";
import { IoLockClosed } from "react-icons/io5";
import { handleCheckout } from "../../utilities/checkoutUtils";

const CartCheckout = ({ onClose }) => {
  const [discountCode, setDiscountCode] = useState("");
  const [discountMessage, setDiscountMessage] = useState("");
  const [discountedTotal, setDiscountedTotal] = useState(null);
  const [specialNote, setSpecialNote] = useState("");

  const applyDiscount = async () => {
    try {
      const res = await fetch(
        `${process.env.REACT_APP_API_URL}/checkout/discount/validate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `ApiKey ${process.env.REACT_APP_API_KEY}`,
          },
          body: JSON.stringify({ code: discountCode }),
        }
      );

      const data = await res.json();
      if (res.ok && data.valid) {
        const discounted =
          cartStore.totalPrice * (1 - data.discount_percent / 100);
        setDiscountedTotal(discounted);
        setDiscountMessage(`✅ ${data.message}`);
      } else {
        setDiscountedTotal(null);
        setDiscountMessage(`❌ ${data.message || "Invalid discount code"}`);
      }
    } catch (err) {
      setDiscountedTotal(null);
      setDiscountMessage("❌ Error validating discount");
    }
  };

  const handleClick = () => {
    handleCheckout({
      cartItems: cartStore.items,
      discountCode,
      note: specialNote,
    });
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "baseline",
          fontWeight: "bold",
          marginBottom: "12px",
          fontSize: "16px",
        }}
      >
        <span>
          Total: {cartStore.totalItems} item
          {cartStore.totalItems > 1 ? "s" : ""}
        </span>
        <span>
          {discountedTotal !== null && (
            <span
              style={{
                textDecoration: "line-through",
                marginRight: "8px",
                opacity: 0.6,
              }}
            >
              ${cartStore.totalPrice.toFixed(2)}
            </span>
          )}
          $
          {discountedTotal !== null
            ? discountedTotal.toFixed(2)
            : cartStore.totalPrice.toFixed(2)}
        </span>
      </div>

      <div style={{ display: "flex", gap: "8px", marginBottom: "4px" }}>
        <input
          type="text"
          placeholder="Discount code"
          value={discountCode}
          onChange={(e) => setDiscountCode(e.target.value)}
          style={inputStyle}
        />
        <button
          onClick={applyDiscount}
          disabled={discountCode.trim() === ""}
          style={{
            ...applyBtnStyle,
            opacity: discountCode.trim() === "" ? 0.5 : 1,
            cursor: discountCode.trim() === "" ? "not-allowed" : "pointer",
          }}
        >
          Apply
        </button>
      </div>

      {discountMessage && (
        <p
          style={{
            fontSize: "13px",
            marginBottom: "12px",
            color: discountMessage.includes("✅") ? "#4ade80" : "#f87171",
          }}
        >
          {discountMessage}
        </p>
      )}

      <textarea
        placeholder="Add any special instructions..."
        value={specialNote}
        onChange={(e) => setSpecialNote(e.target.value)}
        style={{
          width: "95%",
          padding: "10px",
          borderRadius: "8px",
          border: "1px solid rgba(255,255,255,0.15)",
          background: "rgba(255,255,255,0.05)",
          color: "white",
          fontSize: "14px",
          marginBottom: "16px",
        }}
        rows={3}
      />

      <div
        style={{
          fontSize: "13px",
          color: "rgba(255,255,255,0.6)",
          marginBottom: "12px",
        }}
      >
        📦 Shipping & taxes calculated at checkout
      </div>

      <button
        onClick={handleClick}
        style={{
          backgroundColor: "#8b5cf6",
          color: "white",
          width: "100%",
          padding: "12px",
          fontSize: "14px",
          fontWeight: "600",
          border: "none",
          borderRadius: "10px",
          cursor: "pointer",
          transition: "background 0.2s",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          gap: "8px",
        }}
      >
        <span>Checkout</span>
        <IoLockClosed size={16} />
      </button>
    </>
  );
};

const inputStyle = {
  flexGrow: 1,
  padding: "10px",
  borderRadius: "8px",
  border: "1px solid rgba(255,255,255,0.15)",
  background: "rgba(255,255,255,0.05)",
  color: "white",
  fontSize: "14px",
};

const applyBtnStyle = {
  padding: "10px 16px",
  borderRadius: "8px",
  backgroundColor: "#22c55e",
  color: "white",
  fontWeight: "bold",
  fontSize: "14px",
  border: "none",
};

export default CartCheckout;
