import Cookies from "js-cookie";
import { getProductList } from "./productUtils"; // Import the helper function

const productList = getProductList(); // Fetch the product list using the helper function

const CART_COOKIE = "shopping_cart";

// Helper function to fetch product and SKU details
export const findSkuDetails = (productId, skuId) => {
  const product = productList.find((prod) => prod.id === productId);
  if (!product) return null;

  const sku = product.skus.find((sku) => sku.id === skuId);
  const price = parseFloat(sku?.price || product.defaultPrice || 0); // Ensure price is a number
  return sku
    ? {
        productId,
        sku: skuId,
        name: product.title,
        label: sku.label,
        price,
        image: sku.image || product.defaultImage || "", // Optional image field
        stock: sku.stock || 0, // Add stock information
      }
    : null;
};

// Fetch cart from cookies
export const getCart = () => {
  return JSON.parse(Cookies.get(CART_COOKIE) || "[]");
};

// Add or update items in the cart
export const addToCart = (productId, skuId, quantity = 1) => {
  const cart = getCart();

  const itemDetails = findSkuDetails(productId, skuId);
  if (!itemDetails) {
    console.error("Invalid product or SKU specified.");
    return;
  }

  const existingItem = cart.find(
    (item) => item.productId === productId && item.sku === skuId
  );

  const availableStock = itemDetails.stock;

  if (existingItem) {
    const newQuantity = existingItem.quantity + quantity;
    if (newQuantity > availableStock) {
      alert(`Only ${availableStock} items available in stock.`);
      return;
    }
    existingItem.quantity = newQuantity; // Update quantity
  } else {
    if (quantity > availableStock) {
      alert(`Only ${availableStock} items available in stock.`);
      return;
    }
    cart.push({ ...itemDetails, quantity }); // Add new item
  }

  Cookies.set(CART_COOKIE, JSON.stringify(cart), { expires: 7 });
};

// Update the quantity of an item in the cart
export const updateCartItem = (productId, skuId, quantity) => {
  const cart = getCart();

  const item = cart.find(
    (item) => item.productId === productId && item.sku === skuId
  );

  if (item) {
    item.quantity = quantity;
    if (item.quantity <= 0) {
      // Remove the item if quantity is 0 or less
      removeCartItem(productId, skuId);
    } else {
      Cookies.set(CART_COOKIE, JSON.stringify(cart), { expires: 7 });
    }
  }
};

// Remove an item from the cart
export const removeCartItem = (productId, skuId) => {
  const cart = getCart().filter(
    (item) => !(item.productId === productId && item.sku === skuId)
  );

  Cookies.set(CART_COOKIE, JSON.stringify(cart), { expires: 7 });
};

// Clear the entire cart
export const clearCart = () => {
  Cookies.remove(CART_COOKIE);
};
